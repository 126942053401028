import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/global/layout"
import CellarIntro from "../components/cellar/cellar-intro"
import FilterLayout from "../components/filter/filter-layout"

const Cellar = ({
  data: {
    datoCmsCellar,
    allPortalWine,
    allPortalGrape,
    allPortalTag,
    allPortalPairing,
    regionChildren,
    regionParents,
  },
  pageContext,
}) => {
  const { productContext } = pageContext

  const productData = {
    allWines: allPortalWine.nodes,
    allGrapeTypes: allPortalGrape.nodes,
    allWineTags: allPortalTag.nodes,
    allPairingTypes: allPortalPairing.nodes,
    allWineTypes: productContext.allWineTypes,
  }

  const { title, description, heroImage, featuredWines, ribbonText, seoMetaTags, id } =
    datoCmsCellar

  const breadcrumbs = [{ title: title, id: id }]

  return (
    <Layout seo={seoMetaTags}>
      <div>
        <CellarIntro
          breadcrumbs={breadcrumbs}
          title={title}
          description={description}
          image={heroImage}
          items={featuredWines}
          ribbonText={ribbonText}
        />
        <FilterLayout
          isCellar
          productContext={productData}
          defaultFilters={[
            { id: "tag-9", name: "Private Cellar", type: "tag" },
          ]}
          regionChildren={regionChildren.nodes}
          regionParents={regionParents.nodes}
        />
      </div>
    </Layout>
  )
}

export default Cellar

export const query = graphql`
  query CellarQuery {
    allPortalWine {
      nodes {
        ...AllPortalWine
      }
    }
    allPortalGrape {
      nodes {
        ...AllPortalGrape
      }
    }
    allPortalTag {
      nodes {
        ...AllPortalTag
      }
    }
    allPortalPairing {
      nodes {
        ...AllPortalPairing
      }
    }
    datoCmsCellar {
      title
      subHeading
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      description
      ribbonText
      id: originalId
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "750", fit: "crop", w: "1920", q: 60 }
        )
        alt
      }
      featuredWines {
        portalId
        title
        subHeading
        text
        imageOverride {
          gatsbyImageData(
            layout: CONSTRAINED
            imgixParams: { h: "460", fit: "crop", w: "460", q: 60 }
          )
          alt
        }
      }
    }
    regionChildren: allDatoCmsRegionChild {
      nodes {
        id: portalRegionId
        title
        parentRegion {
          id: originalId
        }
      }
    }
    regionParents: allDatoCmsRegionParent(sort: { fields: position }) {
      nodes {
        id: originalId
        title
      }
    }
  }
`
