import React from "react"
import style from "./cellar-intro.mod.scss"
import BorderWrapper from "../border-wrapper/border-wrapper"
import Container from "../container/container"
import { GatsbyImage } from "gatsby-plugin-image"
import Navigator from "../global/navigator"
import Breadcrumbs from "../global/breadcrumbs"
import FeaturedWine from "../featured-wine/featured-wine"

const CellarIntro = ({ title, image, description, ribbonText, items, breadcrumbs }) => {
  return (
    <div className={style.intro}>
      <div>
        <BorderWrapper topColor={"light"} bottomColor={"dark"}>
          {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}

          <div className={style.intro__top}>
            <div className={style.intro__image}>
              {image && (
                <GatsbyImage image={image.gatsbyImageData} alt={image.alt} />
              )}
            </div>
            <Container gutters pt={2} pb={2} width={"small"}>
              <div>
                <h1>{title}</h1>
                <p>{description}</p>
              </div>
            </Container>
          </div>
        </BorderWrapper>
      </div>
      <div className={style.intro__bottom}>
        <Container pt={1} pb={2} gutters width={"medium"}>
          <div className={style.intro__cards}>
            {items.map((item) => (
              <FeaturedWine {...item} />
            ))}
          </div>
        </Container>
      </div>
      {ribbonText && <div className={style.intro__ribbon}><Container gutters width={"small"}>{ribbonText}</Container></div>}
    </div>
  )
}

export default CellarIntro
